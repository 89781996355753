import ReactGA from 'react-ga';
import capitalize from 'Helpers/capitalize-string-first-letter';

export const Tracking = ({ children, id, debug }) => {
    ReactGA.initialize(id, {
        debug: debug
    });
    return children;
};

export const trackingEvent = (eventType, params) => {
    if (eventType === 'page') {
        ReactGA.pageview(params.path, null, capitalize(params.title));
    }

    if (eventType === 'event') {
        ReactGA.event(params);
    }
};
